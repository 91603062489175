import React from "react"
import { Link } from "gatsby"
import tw, { styled } from 'twin.macro'

const Caption = styled.div`
  ${tw`uppercase font-display`}
  display: inline-block;
  font-size: 17px;
  font-weight: bold;
  line-height: 17px;
`

const LinkMark = styled.div`
  background: ${props =>
    `linear-gradient(180deg, ${props.from} 0%, ${props.to} 100%)`};
  box-shadow: 0 0 4px 0 ${props => props.to};
  display: inline-block;
  margin-right: 12px;
  height: 20px;
  width: ${props => (props.thin ? "5px" : "10px")};
`

const defaultGradients = {
  green: ["#05c56b", "#00933e"],
  purple: ["#b070fd", "#481397"],
  red: ["#e3284c", "#aa0024"],
  yellow: ["#f7bc2d", "#c69724"],
}

// Low-emphasis button
const ButtonLE = ({
  children,
  className = "text-white",
  color = "red",
  href,
  target,
  thin = false,
  to,
  gradient,
}) => {
  // Wrap with internal link, smooth scrolling action or external link
  function linkWrapper(children) {
    const rel = target === "_blank" ? "noopener noreferrer" : null
    const smooth = to?.substr(0, 1) === "#"
    if (!!to) {
      if (smooth) {
        return (
          <a href={to} onClick={scroll}>
            {children}
          </a>
        )
      } else {
        return <Link to={to}>{children}</Link>
      }
    } else if (href) {
      return (
        <a href={href} target={target} rel={rel}>
          {children}
        </a>
      )
    } else {
      return children
    }
  }

  function scroll(e) {
    e.preventDefault()
    if (window) {
      document.querySelector(to).scrollIntoView({
        behavior: "smooth",
      })
    }
    return false
  }

  if (! gradient) {
    gradient = defaultGradients[color];
  }

  return linkWrapper(
    <div
      className={`flex flex-row items-center p-2 -mx-2 text-white ${className}`}
    >
      <LinkMark
        from={gradient[0]}
        to={gradient[1]}
        thin={thin}
        className="link-mark"
      />
      <Caption className="link-caption">{children}</Caption>
    </div>
  )
}

export default ButtonLE
