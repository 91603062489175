import React from "react"
import tw, { styled } from "twin.macro"

import Autolink from "components/autolink"
import ButtonLE from "components/button-le"
import Tag from "components/tag"
import YouTube from "components/youtube.js"

const Title = styled.h3`
  ${tw`font-display text-center m:text-left text-white py-2`}
  font-size: 30px;
  line-height: 35px;
  @media (min-width: 640px) {
    font-size: 45px;
    line-height: 53px;
  }
  @media (min-width: 1024px) {
    font-size: 50px;
    line-height: 56px;
  }
`

const Body = styled.div`
  ${tw`font-body text-white py-4`}
  font-size: 15px;
  line-height: 27px;
`

const Styles = styled.div`
  ${tw`px-2 py-16 flex flex-wrap l:flex-row overflow-hidden mx-auto max-w-s m:max-w-m l:max-w-l`}

  &.on-list {
    ${tw`pb-8`}
  }

  &.first {
    ${tw`pt-0`}
  }
`

export default ({ cb, className = "" }) => {
  if (!cb) {
    return <></>
  }

  return (
    <Styles className={className}>
      <div className="mx-auto max-w-s m:max-w-l l:w-1/2 l:pr-16 flex flex-col text-center items-center m:text-left m:items-start justify-center">
        {cb.label && <Tag className="red">{cb.label}</Tag>}
        {cb.title && <Title>{cb.title}</Title>}
        {cb.headerContent && (
          <Body dangerouslySetInnerHTML={{ __html: cb.headerContent?.html }} />
        )}
        {cb.mainContent && (
          <Body dangerouslySetInnerHTML={{ __html: cb.mainContent?.html }} />
        )}
        {cb.callToAction && (
          <ButtonLE
            color="green"
            href={cb.linkUrl}
            target={cb?.linkTargetBlank ? "_blank" : null}
          >
            {cb.callToAction}
          </ButtonLE>
        )}
      </div>
      <div className="w-full l:w-1/2 l:px-2 py-4 l:px-0 overflow-visible flex flex-col justify-center">
        {cb.youtubeId && <YouTube videoId={cb.youtubeId} />}
        {!cb.youtubeId && cb.featuredImage && (
          <Autolink to={cb.linkUrl} blank={cb?.linkTargetBlank}>
            <img src={cb.featuredImage.url} alt="" />
          </Autolink>
        )}
      </div>
    </Styles>
  )
}
