import React from "react"
import tw, { styled } from "twin.macro"

import Autolink from "components/autolink"
import ButtonLE from "components/button-le"
import Tag from "components/tag"

const Header = styled.div`
  ${tw`font-body text-white py-2`}
  font-size: 15px;
  line-height: 27px;

  h2 {
    ${tw`font-display text-center m:text-left text-white pb-2`}
    font-size: 30px;
    line-height: 35px;
    @media (min-width: 640px) {
      font-size: 45px;
      line-height: 53px;
    }
    @media (min-width: 1024px) {
      font-size: 50px;
      line-height: 56px;
    }
  }
`

const Styles = styled.div`
  ${tw`px-2 py-16 flex flex-wrap l:flex-row overflow-hidden mx-auto max-w-s m:max-w-m l:max-w-l`}

  &.takeover {
    ${tw`px-0 pt-14`}

    ${Header} {
      h2 {
        @media (min-width: 1024px) {
          font-size: 48px;
          line-height: 125%;
        }
      }
    }
  }
`

const FeaturedWhitepaper = ({ cb, className = "" }) => {
  if (!cb) {
    return null
  }

  return (
    <Styles className={className} id={cb.identifier}>
      <div className="mx-auto max-w-s m:max-w-l l:w-1/2 l:pr-16 flex flex-col text-center items-center m:text-left m:items-start justify-center">
        {cb.extraData?.label && <Tag className="red">{cb.extraData.label}</Tag>}
        <Header dangerouslySetInnerHTML={{ __html: cb.headerContent?.html }} />
        {cb.callToAction && (
          <ButtonLE color="green" href={cb.linkUrl}>
            {cb.callToAction}
          </ButtonLE>
        )}
      </div>
      <div className="w-full l:w-1/2 l:px-2 py-4 l:px-0 overflow-visible flex flex-col justify-center">
        {cb.featuredImage && (
          <Autolink to={cb.linkUrl} blank={false}>
            <img
              src={cb.featuredImage.urlWebp || cb.featuredImage.url}
              alt=""
            />
          </Autolink>
        )}
      </div>
    </Styles>
  )
}

export default FeaturedWhitepaper
