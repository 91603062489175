import React from "react"
import tw, { styled } from "twin.macro"


const PageHeaderContentStyles = styled.div`
  ${tw`pt-8 m:pt-12 pb-10 m:pb-16 text-center`}
  
  h1 {
    ${tw`py-2 font-display text-white`}
    font-size: 30px;
    line-height: 35px;
    @media (min-width: 640px) {
      font-size: 45px;
      line-height: 53px; 
    }
    @media (min-width: 1024px) {
      font-size: 60px;
      line-height: 79px;
    }
  }

  h3 {
    ${tw`font-display text-yellow `}
    font-size: 14px;
    line-height: 20px;
    @media (min-width: 640px) {
      font-size: 18px;
      line-height: 25px;
    }
  }

  p {
    ${tw`font-body text-white`}
    font-size: 1.125rem;
    line-height: 1.5;
    text-align: center;
    margin-top: 0.25rem;
    @media (min-width: 640px) {
      font-size: 1.25rem;
      line-height: 1.5;
      margin-top: 0.75rem;
    }
  }
`

export const HeaderBackground = styled.div`
  background-image: ${(props) => (props.bg ? `url(${props.bg})` : "")};
  background-size: cover;
  background-position: center center;
`

const ContentWrapper = styled.div`
  ${tw`pt-0 l:px-32  flex flex-col justify-center items-center content-center mx-auto l:max-w-l`};
  

  @media (max-width: 1200px) {
    max-width: 90%;
  }
}
`

const Gradient = styled.div`
  ${(props) =>
    props.active
      ? `background: linear-gradient(270deg,rgba(77, 10, 23, .7) 0%,rgba(0, 0, 0, .7) 100%);`
      : ""}
`

const PageHeader = ({ bg, children, content, gradient, contentClass }) => (
  <HeaderBackground bg={bg} className="bg-black">
    <Gradient active={gradient}>
      {content && (
        <ContentWrapper>
          <PageHeaderContentStyles
            className={contentClass}
            dangerouslySetInnerHTML={{ __html: `<div>${content}</div>` }}
          />
        </ContentWrapper>
      )}
      {children}
    </Gradient>
  </HeaderBackground>
)

export default PageHeader
