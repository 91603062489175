import React, { useEffect, useLayoutEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import tw, { styled } from "twin.macro"
import ReactTooltip from "react-tooltip"
import { Swiper, SwiperSlide } from "swiper/react"
import { Helmet } from "react-helmet"

import DownArrow from "images/icons/arrow-down-white.svg"
import InfoIcon from "images/icons/information.svg"
import ButtonLE from "components/button-le"
import ArrowContainer from "components/arrow-container"
import PageArrow from "components/page-arrow"

const Title = styled.h2`
  ${tw`font-display text-white text-center pb-6 m:pb-12`}
  font-size: 20px;
  line-height: 30px;
  @media (min-width: 640px) {
    font-size: 28px;
    line-height: 38px;
  }
  @media (min-width: 1024px) {
    font-size: 36px;
    line-height: 46px;
  }
`

const Subtitle = styled.h3`
  ${tw`font-display text-white text-center l:text-left pt-8 pb-6`}
  font-size: 17px;
  line-height: 26px;
  @media (min-width: 640px) {
    font-size: 20px;
  }

  .__react_component_tooltip.show {
    background-color: black;
    opacity: 0.9;
  }
`

const ShowTitle = styled.div`
  ${tw`font-display text-white py-1 m:py-2 px-3`}
  font-size: 11px;
  line-height: 17px;
  @media (min-width: 640px) {
    font-size: 13px;
    line-height: 17px;
  }
`

const Growth = styled.div`
  ${tw`font-secondary text-springgreen-light px-3 pb-3`}
  font-size: 24px;
  line-height: 24px;
  @media (min-width: 640px) {
    font-size: 30px;
    line-height: 30px;
  }
`

const ShowCover = styled.div`
  ${tw`relative`}
  background-image: ${(props) =>
    props?.posters?.small ? `url(${props.posters.small})` : ""};
  @media (min-width: 640px), (-webkit-min-device-pixel-ratio: 2) {
    background-image: ${(props) =>
      props?.posters?.medium ? `url(${props.posters.medium})` : ""};
  }
  background-size: cover;
  height: 216px;
  width: 146px;
  @media (min-width: 640px) {
    height: 274px;
    width: 187px;
  }
  @media (min-width: 1200px) {
    height: 274px;
    width: 187px;
  }
`

const ShowBackground = styled.div`
  ${tw`absolute top-0 left-0 flex flex-col items-start justify-end`}
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
  height: 216px;
  width: 146px;
  @media (min-width: 640px) {
    height: 274px;
    width: 187px;
  }
  @media (min-width: 1200px) {
    height: 274px;
    width: 187px;
  }
`

const ShowCard = ({ show }) => {
  function round(value, decimals) {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals).toFixed(
      decimals
    )
  }

  return (
    <ShowCover posters={show.posters}>
      <a href={show.seo_page} rel="noopener noreferrer" target="_blank">
        <ShowBackground>
          <ShowTitle>{show.title}</ShowTitle>
          <Growth>+{round(show.demand_growth, 1)}%</Growth>
        </ShowBackground>
      </a>
    </ShowCover>
  )
}

const MarketButtons = styled.div`
  ${tw`mt-8 hidden m:flex flex-wrap justify-between`}
  background-color: rgba(12,12,12,0.8);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);
  border-radius: 5px;
`

const MarketButton = styled.div`
  ${tw`font-body text-white text-left px-3 flex flex-row items-center`}
  border: 1px solid rgba(12,12,12,0.8);
  cursor: pointer;
  font-size: 16px;
  height: 44px;
  border-radius: 5px;
  transition: background 200ms;
  width: 185px;

  &:hover {
    background-color: #2c2c2c;
  }

  &.selected {
    ${tw`font-display`};
    background-color: #281040;
    border: 1px solid #b070fd;

    &:hover {
      background-color: #281040;
    }
  }

  img {
    height: 20px;
    margin-right: 8px;
    width: 28px;
  }
`

const MarketSelect = styled.select`
  ${tw`font-display text-white`}
  display: block;

  font-size: 16px;
  line-height: 16px;
  height: 44px;
  padding: 12px;
  padding-left: 50px;
  padding-top: 15px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #b070fd;
  border-radius: 5px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #281040;
  background-repeat: no-repeat, no-repeat;
  background-position: right 12px top 50%, left 10px top 50%;
  background-size: 20px auto, 28px 20px;
  &:focus {
    outline: none;
  }
`

export default ({ showCTA = true }) => {
  const showLimit = 18
  const data = useStaticQuery(graphql`
    query {
      allTvDemandJson {
        nodes {
          markets {
            homepage
            country_name
            country_iso
            slug
            market_name
            heating_up {
              demand_growth
              posters {
                small
                medium
              }
              title
              seo_page
            }
          }
        }
      }
      gcms {
        websiteSetting(where: { name: "Measurement page localization" }) {
          jsonValue
        }
      }
    }
  `)
  const markets = data.allTvDemandJson.nodes[0].markets.filter(
    (m) => m.homepage
  )
  const [currentSlide, setCurrentSlide] = useState(null)
  const [currentMarket, setMarket] = useState(markets[0])
  const [swiper, setSwiper] = useState(null)

  const shows = currentMarket.heating_up.slice(0, showLimit)
  const { websiteSetting } = data.gcms

  const slideChange = () => {
    setCurrentSlide(swiper?.activeIndex || null)
  }

  const swiperConfig = {
    centeredSlides: false,
    loop: false,
    slidesPerView: 2,
    spaceBetween: 8,
    breakpoints: {
      1200: {
        slidesPerView: 6,
        spaceBetween: 16,
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 8,
      },
    },
  }

  const pageSize = () =>
    swiper?.width >= 1200 ? 6 : swiper?.width >= 640 ? 3 : 2

  const pageUp = () => {
    swiper.slideTo(Math.min(showLimit, swiper.activeIndex + pageSize()))
    slideChange()
  }

  const pageDown = () => {
    swiper.slideTo(Math.max(0, swiper.activeIndex - pageSize()))
    slideChange()
  }

  const switchMarket = (market) => {
    setMarket(market)
    if (swiper) {
      swiper.slideTo(0)
    }
  }

  const selectMarket = (e) => {
    const currentSlug = e.currentTarget.value
    switchMarket(markets.find((m) => m.slug === currentSlug))
  }

  useEffect(() => {
    slideChange()
    // Listen to slider event
    if (swiper) {
      swiper.on("slideChange", slideChange)
    }
  }, [swiper])

  useLayoutEffect(() => {
    const defaultMarket =
      markets.find((m) => m.country_iso === websiteSetting.jsonValue.default) ||
      markets[0]
    if (
      typeof window !== `undefined` &&
      window.country &&
      window.country.code !== null
    ) {
      // Check if there is a country code override in the settings
      const countryCode = websiteSetting?.jsonValue[window.country.code]
        ? websiteSetting.jsonValue[window.country.code]
        : window.country.code
      const selectedMarket = markets.find((m) => m.country_iso === countryCode)
      if (selectedMarket) {
        switchMarket(selectedMarket)
      } else {
        switchMarket(defaultMarket)
      }
    } else {
      switchMarket(defaultMarket)
    }
  }, [])

  return (
    <>
      <Helmet>
        <script type="text/javascript" id="geoip" src="/js/geo.js"></script>
      </Helmet>
      <div className="pt-16 mx-auto max-w-s m:max-w-m l:max-w-l">
        <Title>
          Explore global demand metrics based on the world’s largest audience
          behavior dataset
        </Title>
        <hr />
        <MarketButtons>
          {markets.map((market, idx) => (
            <MarketButton
              key={idx}
              onClick={() => switchMarket(market)}
              selected={currentMarket.slug === market.slug}
              className={currentMarket.slug === market.slug ? "selected" : ""}
            >
              <img
                src={`https://parrot-portalassets.s3.amazonaws.com/flags/${market.country_iso.toLowerCase()}.png`}
                alt={`Flag of ${market.market_name}`}
              />
              <span className="pt-1">
                {market.slug === "UK" ? "UK" : market.country_name}
              </span>
            </MarketButton>
          ))}
        </MarketButtons>
        <div className="block m:hidden">
          <MarketSelect
            style={{
              backgroundImage: `url(${DownArrow}), url(https://parrot-portalassets.s3.amazonaws.com/flags/${currentMarket.country_iso.toLowerCase()}.png)`,
            }}
            onChange={selectMarket}
            value={currentMarket.slug}
          >
            {markets.map((market, idx) => (
              <option key={idx} value={market.slug}>
                {market.country_name}
              </option>
            ))}
          </MarketSelect>
        </div>
        <Subtitle>
          TV shows heating up in{" "}
          <span className="whitespace-nowrap">{currentMarket.market_name}</span>
          <img
            src={InfoIcon}
            className="hidden m:inline-block mx-2"
            height="14px"
            width="14"
            alt=""
            data-tip="A selection of the top TV shows in the selected market with the highest demand growth rate over the last 30 days, compared to the prior 30 days, based on Parrot Analytics’ global cross-platform TV demand data. Only the top 200 titles in the market are considered for this ranking."
          />
          <ReactTooltip
            className="font-body text-white bg-black w-64 rounded opacity-1"
            delayHide={200}
            effect="solid"
            place="top"
            overridePosition={({ left, top }) => {
              if (top < 100) {
                top = top + 300
              }
              return { top, left }
            }}
          />
        </Subtitle>
      </div>
      <div className="relative w-full overflow-hidden">
        <div className="mx-auto max-w-s m:max-w-m l:max-w-l overflow-visible m:px-12 l:px-0">
          <Swiper onSwiper={setSwiper} {...swiperConfig}>
            {shows.map((show, idx) => (
              <SwiperSlide key={idx}>
                <ShowCard show={show} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="absolute top-0 left-0 w-full">
            <ArrowContainer>
              <PageArrow
                dir="left"
                enabled={currentSlide > 0}
                onClick={pageDown}
              />
              <PageArrow
                dir="right"
                enabled={currentSlide + pageSize() < showLimit}
                onClick={pageUp}
              />
            </ArrowContainer>
          </div>
        </div>
      </div>
      {showCTA && (
        <div className="flex flex-col items-center pt-12 m:pt-24">
          <ButtonLE color="green" to="/products/demand360/lite/">
            Find out more
          </ButtonLE>
        </div>
      )}
    </>
  )
}
